.tool-body {
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0.5rem 0;
  font-family: oscine, serif;
  font-style: normal;
  font-weight: 600;
  color: white;
  text-transform: none;
}

h1 {
  font-size: 20px;;
}

h3 {
  color:#00003C;
}

a, p {
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  font-family: Tahoma, Arial, sans-serif; 
  font-style: normal; 
  font-weight: 400; 
  color: #00003c;
}
/* search functionality */

#searchinput{
  min-width: 100px;
  max-width: 500px;
  width: 75%;
  padding: 10px;
  margin: 10px auto ;
  outline: none;
  display: flex;
  flex-direction: row;
}



/* Tiles css */

.subtitle {
  font-weight: bold;
}

.summary-header, .reg-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  margin-left: 10px;
}

.link, .align-right {
  margin: 0 10px 0 auto;
}

.templateContainer {
  padding-top: 10px;
  margin: 0;
  margin-top: 15px;
  height: 800px;
  min-width: 800px;
  max-width: 1200px;
  width: 80%;
  overflow-y: scroll; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  background-color: #F8F8F8;
}

.templateContainer .template_Container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; 
  margin:auto;
  padding: 10px 0;
}



/* Map css */


pre {
  font-size: 7em;
}
.marks .land {
  fill: #66E4FF;
}
.marks .sphere {
  fill: #ececec;
}
.marks .interiors {
  fill: none;
  stroke: #66E4FF;
}
.marks .graticules {
  fill: none;
  stroke: white;
}

/* circles */

circle {
  fill: #0D0D80;
  opacity: 0.7;
}

/*accordion*/

.MuiPaper-root {
  margin: 10px 0;
}

.card2:hover {
  -webkit-box-shadow: 5px 5px 8px 2px rgba(0,0,0,0.1); 
  box-shadow: 5px 5px 8px 2px rgba(0,0,0,0.1);
}

.MuiCardHeader-root {
  background-color: #000066;
}

.MuiCardContent-root {
  background-color: white;
}

.MuiCardActions-root {
  background-color: #000066;
}

.expand, .subheader, .cardaction{
  color:white;
}


.cardaction {
  padding-left: 7px;
}

.expand:hover {
  background-color: #00D2FF;
  color:#00003C;
}

